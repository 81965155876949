.settingsButtons {
  text-align: right;
  grid-area: buttons;
  background: hsla(206, 31%, 85%, 1);
  margin: 0;
  padding-top: 5px;
}

.optionNote {
  background: hsl(0, 80%, 80%);
  border: solid 2px hsl(0, 100%, 40%);
  padding: 5pt;
  margin-top: 0.5em;
  font-family: var(--interface-font);
}

.documentSettings {
  grid-column: 1/3;
}
