.tools-buttons button {
  text-decoration: none;
  color: white;
  display: block;
  border: 2px solid white;
  width: 70px;
  height: 40px;
  margin: 0 auto 10px;
  border-radius: 20px;
  background: transparent;
  cursor: pointer;
  font-family: var(--system-font);
  line-height: 1;
}

.tools-buttons button:hover {
  box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 1);
}

.tools-buttons button::before {
  font-family: Symbola;
}

.tools-buttons button::before {
  display: block;
  position: relative;
  font-size: 1.1em;
}

.editbutton::before {
  content: '\1F589';
}

.previewbutton::before {
  content: '↻';
  transform: rotate(90deg);
}

.printbutton::before {
  content: '\1F5B6';
}

.tools-buttons button.disabled {
  color: hsl(0, 0%, 60%);
  border-color: hsl(0, 0%, 60%);
}
.tools-buttons button.disabled:hover {
  box-shadow: none;
  cursor: default;
}
.documentSettingsbutton::before {
  content: '⚙';
}
.savebutton::before {
  content: '\1F4BE';
  margin-bottom: 2px;
}
.newbutton::before {
  content: '\1F5CB';
}

.infobutton::before {
  content: '?';
  font-family: var(--system-font);
  font-weight: bold;
}

.exitbutton.exitbutton::before {
  content: '✗';
}
