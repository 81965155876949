[data-theme='Wesley'] {
  .rubric {
    font-style: normal;
    margin-left: 5mm;
  }
  h2 {
    font-style: normal;
    text-align: left;
    font-size: 1em;
    font-weight: normal;
    text-transform: uppercase;
  }
  h3 {
    text-align: left;
    font-weight: normal;
    font-size: 1em;
    font-variant-caps: all-small-caps;
  }
  h1 {
    margin: 1em 0;
    font-size: 1.1em;
    font-weight: normal;
    text-transform: uppercase;
    text-indent: 0;
    padding-left: 0;
    font-style: normal;
    &::before {
      content: none;
    }
  }
  style + h1,
  .cover + h1,
  .button + h1,
  h1:first-child {
    margin-top: 0;
  }
  .responsorialPsalm {
    .stanza {
      margin-left: 14mm;
      .preview & {
        margin-left: 8.4mm;
      }
    }
    .response {
      padding-left: 8mm;
      text-indent: -8mm;
      margin-left: 6mm;
      &::before {
        width: 8mm;
      }
    }
  }
  .minister {
    margin-left: 14mm;
    preview & {
      margin-left: 8.4mm;
    }
  }
  .people {
    padding-left: 8mm;
    text-indent: -8mm;
    margin-left: 6mm;
    &::before {
      width: 8mm;
    }
    &[data-no-all='true'] {
      text-indent: 0;
    }
    & + .people::before {
      content: '\a0';
    }
    @at-root [data-theme='Wesley'] .preview .people {
      padding-left: 4.8mm;
      text-indent: -4.8mm;
      margin-left: 3.6mm;
      &::before {
        width: 4.8mm;
      }
    }
  }
  .psalm {
    .verse {
      padding-left: 14mm;
      &[data-verse-number] {
        text-indent: -8mm;
        &::before {
          width: 8mm;
        }
      }
    }
    @at-root [data-theme='Wesley'] .preview {
      .verse {
        padding-left: calc(14mm * 0.6);
        &[data-verse-number] {
          text-indent: calc(-8mm * 0.6);
          &::before {
            width: calc(8mm * 0.6);
          }
        }
      }
    }
  }
}
