.subscribe {
  font-family: 'Roboto';
  position: relative;
  .loading {
    position: absolute;
    background: white;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    align-items: center;
  }
}
