.minister {
  display: block;
  margin-top: 0.5em;
  margin-left: 10mm;
}
.minister-container.noindent .line {
  display: unset;
  text-indent: 0;
  padding-left: 0;
}
.preview .minister {
  margin-left: 6mm;
}
