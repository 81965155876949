[data-theme='Gregory'] {
  h1 {
    text-align: center;
    color: black;
    font-size: 1.3em;
    margin-bottom: 3em;
    font-style: normal;
    &::before {
      content: none;
    }
  }
  h2 {
    font-size: 1.1em;
    text-align: center;
    font-style: normal;
    color: var(--highlightColour);
  }
  h3 {
    font-size: 1em;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    color: var(--highlightColour);
  }
  .people {
    padding-left: 0;
    text-indent: 0;
    &::before {
      content: none;
    }
  }
  .minister {
    margin-left: 0;
  }
  .rubric {
    text-indent: 5mm;
    font-style: normal;
    @at-root .preview .rubric {
      text-indent: 3mm;
    }
  }
  .psalm .verse {
    padding-left: 0;
  }
  .psalm {
    h2 + .people,
    h3 + .people,
    .people:first-child,
    .people:last-child {
      margin-left: 0;
      text-indent: 0;
      padding-left: 10mm;
      text-indent: -10mm;
      .indent {
        display: none;
      }
      &::before {
        content: 'Ant.';
        display: inline-block;
        width: 10mm;
        text-indent: -10mm;
      }
    }
    .people {
      margin-left: 10mm;
    }
    .verse {
      margin-left: 10mm;
    }
  }
  .preview {
    .psalm {
      .people:first-of-type,
      .people:last-child {
        margin-left: 0;
        text-indent: 0;
        padding-left: 6mm;
        text-indent: -6mm;
        &::before {
          width: 6mm;
          text-indent: -6mm;
        }
      }
      .people,
      .verse {
        margin-left: 6mm;
      }
    }
    .rubric {
      text-indent: 3mm;
    }
  }
}
