.dashboard {
  tbody {
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4) {
      font-size: 0.75em;
    }
  }

  tr.MuiTableRow-hover:hover {
    background-color: hsl(191.5, 10%, 80%);
  }

  .bottom-control {
    text-align: right;
    margin-top: 10px;
  }

  .loading {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: absolute;
    background: white;
    z-index: 5;
    opacity: 0.6;
  }
}
