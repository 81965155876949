.userInfo {
  font-family: 'Roboto';
  position: relative;
  height: fit-content;
  .loading {
    position: absolute;
    background: white;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    align-items: center;
  }
  th {
    text-align: right;
    padding-right: 30px;
  }
}
