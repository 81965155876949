:root {
  --system-font: Amaranth;
  --interface-font: Roboto;
  --system-color: #5a86a8;
}

html,
body,
.grid-container {
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-size: 14pt;
}

.grid-container {
  display: grid;
  grid-template-rows: 75px calc(100vh - 75px);
  grid-template-areas:
    'title'
    'content';
  background: gray;
}

.title {
  grid-area: title;
  color: white;
  font-size: 2.5em;
  font-weight: bold;
  font-family: var(--system-font);
  background: linear-gradient(to bottom, #1e5799 0%, var(--system-color) 80%);
  padding: 5px;
  overflow: hidden;
}
.title em {
  color: hsl(63, 10%, 70%);
}
.title a {
  text-decoration: none;
  color: unset;
}
@media (max-width: 750px) {
  .title em {
    display: none;
  }
}

button.logout {
  position: absolute;
  right: 0;
}
