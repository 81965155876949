.infoDialogue .content {
  margin: 10px;
  font-family: var(--interface-font);
}

.infoDialogue {
  position: relative;
  top: 20%;
  width: 60%;
  margin: 0 auto;
  background: white;
  max-height: 80%;
  border-radius: 10px;
  overflow-y: auto;
  box-shadow: 3px 3px 5px 1px #555;
}

.infoDialogue a {
  color: var(--system-color);
  text-decoration: none;
}

.infoDialogue a:hover {
  text-decoration: underline;
}

.infoDialogue svg {
  float: right;
  margin: 0 10px;
  width: 200px;
  height: 200px;
}
