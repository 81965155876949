.login {
  text-align: right;
  .MuiContainer-root {
    padding-top: 10px;
  }

  button {
    margin: 10px 0;
  }
}

.app {
  padding: 10px;
  font-family: Roboto, sans-serif;
}
