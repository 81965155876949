.blocking {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #666;
  opacity: 0.9;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  margin: 0 auto;
  text-align: center;
  font-family: var(--system-font);
  font-size: 1.2em;
  border: 4px double;
  color: white;
  background-color: #020202;
  padding: calc(20px + 1em) 20px;
}

.message p {
  margin-top: 0;
}
